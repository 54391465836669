define("global-admin/components/form-global-roles-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uF2crcCi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"label\"],[14,0,\"acc-label\"],[14,5,\"min-height: 50px;\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0,[\"active\"]]]]]],[2,\" \"],[6,[37,3],[[35,0,[\"hasTranslation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],[[35,0,[\"translationKey\"]]],null]]],\"parameters\":[]},{\"statements\":[[1,[35,0,[\"role\",\"displayName\"]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[6,[37,3],[[35,0,[\"hasTranslation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],[[35,0,[\"descriptionTranslationKey\"]]],null]]],\"parameters\":[]},{\"statements\":[[1,[35,0,[\"role\",\"detail\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"input\",\"if\"]}",
    "moduleName": "global-admin/components/form-global-roles-row/template.hbs"
  });

  _exports.default = _default;
});