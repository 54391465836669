define("shared/components/schema/input-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E0JjV51Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"mr-20 hand\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"selection\",\"value\"],[[35,0],true]]]],[2,\" \"],[1,[30,[36,2],[\"schema.inputBoolean.y\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"label\"],[14,0,\"hand\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"selection\",\"value\"],[[35,0],false]]]],[2,\" \"],[1,[30,[36,2],[\"schema.inputBoolean.n\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"radio-button\",\"t\"]}",
    "moduleName": "shared/components/schema/input-boolean/template.hbs"
  });

  _exports.default = _default;
});