define("global-admin/security/authentication/freeipa/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nTNd3Z2m",
    "block": "{\"symbols\":[],\"statements\":[[19,\"ldap-config\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "moduleName": "global-admin/security/authentication/freeipa/template.hbs"
  });

  _exports.default = _default;
});