define("@rancher/ember-api-store/utils/ajax-promise", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ajaxPromise = ajaxPromise;
  _exports.default = void 0;

  function ajaxPromise(opt, justBody) {
    var promise = new Ember.RSVP.Promise(function (resolve, reject) {
      _jquery.default.ajax(opt).then(success, fail);

      function success(body, textStatus, xhr) {
        if (justBody === true) {
          resolve(body, 'AJAX Response: ' + opt.url + '(' + xhr.status + ')');
        } else {
          resolve({
            xhr: xhr,
            textStatus: textStatus
          }, 'AJAX Response: ' + opt.url + '(' + xhr.status + ')');
        }
      }

      function fail(xhr, textStatus, err) {
        reject({
          xhr: xhr,
          textStatus: textStatus,
          err: err
        }, 'AJAX Error:' + opt.url + '(' + xhr.status + ')');
      }
    }, 'Raw AJAX Request: ' + opt.url);
    return promise;
  }

  var _default = ajaxPromise;
  _exports.default = _default;
});