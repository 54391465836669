define("ember-engines/components/link-to-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LinkToExternal;

  if (true) {
    LinkToExternal = class LinkToExternal extends Ember.LinkComponent {
      _namespaceRoute(targetRouteName) {
        const owner = Ember.getOwner(this);

        const externalRoute = owner._getExternalRoute(targetRouteName);

        return externalRoute;
      } // override LinkTo's assertLinkToOrigin method to noop. In LinkTo, this assertion
      // checks to make sure LinkTo is not being used inside a routeless engine
      // See this PR here for more details: https://github.com/emberjs/ember.js/pull/19477


      assertLinkToOrigin() {}

    };
  } else {
    LinkToExternal = Ember.LinkComponent.extend({
      didReceiveAttrs() {
        this._super(...arguments);

        const owner = Ember.getOwner(this);

        if (owner.mountPoint) {
          // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
          const routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';
          const routeName = Ember.get(this, routeKey);

          const externalRoute = owner._getExternalRoute(routeName);

          Ember.set(this, routeKey, externalRoute);
        }
      }

    });
  }

  var _default = LinkToExternal;
  _exports.default = _default;
});