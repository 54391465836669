define("global-admin/security/policies/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pooy2SRE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\",\"editing\"],[[35,0,[\"policy\"]],true]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"new-edit-policy\"]}",
    "moduleName": "global-admin/security/policies/edit/template.hbs"
  });

  _exports.default = _default;
});