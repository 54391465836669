define("shared/components/schema/input-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8aK3U0yi",
    "block": "{\"symbols\":[\"list\",\"group\",\"svc\"],\"statements\":[[10,\"select\"],[15,0,[31,[[34,4]]]],[15,\"onchange\",[30,[36,6],[[32,0],[30,[36,5],[[35,0]],null]],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n  \"],[10,\"option\"],[15,\"selected\",[30,[36,1],[[35,0],null],null]],[12],[1,[30,[36,7],[\"schema.inputContainer.prompt\"],null]],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,9],[[35,8]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"optgroup\"],[15,\"label\",[32,2]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"option\"],[15,\"selected\",[30,[36,1],[[35,0],[32,3,[\"id\"]]],null]],[15,2,[32,3,[\"id\"]]],[12],[1,[32,3,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"eq\",\"-track-array\",\"each\",\"selectClass\",\"mut\",\"action\",\"t\",\"grouped\",\"-each-in\"]}",
    "moduleName": "shared/components/schema/input-container/template.hbs"
  });

  _exports.default = _default;
});