define("shared/components/cluster-driver/driver-import-gke/component", ["exports", "shared/mixins/cluster-driver", "shared/utils/util", "shared/components/cluster-driver/driver-import-gke/template"], function (_exports, _clusterDriver, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    google: Ember.inject.service(),
    layout: _template.default,
    configField: 'gkeConfig',
    step: 1,
    loading: false,
    nodeForInfo: null,
    loadingClusters: false,
    loadFailedAllClusters: false,
    regionChoices: null,
    errors: null,
    otherErrors: null,
    clusterErrors: null,
    selectedCred: null,
    isPostSave: false,
    config: null,
    zones: null,
    locationType: null,
    isEdit: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        errors: [],
        clusterErrors: [],
        otherErrors: [],
        zones: [],
        locationType: this.google.defaultZoneType,
        regionChoices: this.google.regions.map(region => ({
          name: region
        }))
      });

      if (this.isEdit) {
        const cloudCredId = Ember.get(this, 'model.cluster.gkeConfig.googleCredentialSecret');
        const cloudCred = (this.model.cloudCredentials || []).find(cc => cc.id === cloudCredId);

        if (!Ember.isEmpty(cloudCred)) {
          Ember.run.next(() => {
            this.send('finishAndSelectCloudCredential', cloudCred);
          });
        }
      } else {
        this.bootstrapGkeV2Cluster();
      }
    },

    actions: {
      clickNext() {},

      finishAndSelectCloudCredential(cred) {
        if (Ember.isEmpty(cred)) {
          Ember.set(this, 'config.googleCredentialSecret', null);
          Ember.set(this, 'selectedCred', null);
        } else {
          Ember.set(this, 'config.googleCredentialSecret', cred.id);
          Ember.set(this, 'selectedCred', cred);
          this.send('checkServiceAccount');
        }
      },

      checkServiceAccount(cb) {
        Ember.set(this, 'errors', []);
        return Ember.RSVP.all([this.google.fetchZones(this.model.cluster, this.saved)]).then(resp => {
          const [zones] = resp;
          Ember.setProperties(this, {
            step: 2,
            zones
          });

          if (cb) {
            cb(true);
          }
        }).catch(err => {
          this.send('errorHandler', err);

          if (cb) {
            cb(false);
          }
        });
      },

      async loadClusters(cb) {
        Ember.set(this, 'errors', []);
        const errors = [];
        let step = 3;
        let allClusters;
        Ember.set(this, 'loadingClusters', true);

        try {
          allClusters = await this.google.fetchClusters(this.cluster, this.saved ?? false);
          Ember.setProperties(this, {
            allClusters: (allClusters || []).map(c => {
              return {
                label: c.name,
                value: c.name
              };
            }),
            step
          });
          Ember.setProperties(this, {
            loadingClusters: false,
            step
          });

          if (cb) {
            cb();
          }
        } catch (err) {
          errors.pushObject(`Failed to load Clusters from GKE: ${err.message}`); // EKS List Clusters API fails sometimes to list this, user cnn input a cluster name though so dont fail

          Ember.setProperties(this, {
            loadFailedAllClusters: true,
            errors
          });

          if (cb) {
            cb(false, err);
          }
        } finally {
          Ember.setProperties(this, {
            loadingClusters: false,
            step
          });
        }
      },

      setZone(selection) {
        if ((selection === null || selection === void 0 ? void 0 : selection.type) === 'change') {
          return;
        }

        const {
          name: zone
        } = selection;
        Ember.setProperties(this.config, {
          zone,
          'region': null
        });

        if (this.step > 2) {
          this.send('loadClusters');
        }
      },

      setRegion(selection) {
        if ((selection === null || selection === void 0 ? void 0 : selection.type) === 'change') {
          return;
        }

        const {
          name: region
        } = selection;
        Ember.setProperties(this.config, {
          'zone': null,
          region
        });

        if (this.step > 2) {
          this.send('loadClusters');
        }
      }

    },
    locationTypeChanged: Ember.observer('locationType', function () {
      const {
        config,
        locationType
      } = this;

      if (locationType === 'zonal') {
        Ember.setProperties(config, {
          'zone': 'us-central1-c',
          'region': null
        });
      } else {
        Ember.setProperties(config, {
          'zone': null,
          'region': 'us-central1'
        });
      }

      if (this.step > 2) {
        this.send('loadClusters');
      }
    }),
    zoneChoices: Ember.computed('zones.[]', function () {
      let out = (Ember.get(this, 'zones') || []).slice();
      out.forEach(obj => {
        Ember.setProperties(obj, {
          sortName: (0, _util.sortableNumericSuffix)(obj.name),
          displayName: `${obj.name} (${obj.description})`,
          disabled: obj.status.toLowerCase() !== 'up'
        });
      });
      return out.sortBy('sortName');
    }),
    disableImport: Ember.computed('step', 'config.{googleCredentialSecret,clusterName}', function () {
      const {
        step,
        config: {
          googleCredentialSecret,
          clusterName
        }
      } = this;

      if (step <= 3 && !Ember.isEmpty(googleCredentialSecret) && !Ember.isEmpty(clusterName)) {
        return false;
      }

      return true;
    }),
    cloudCredentials: Ember.computed('model.cloudCredentials', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      return cloudCredentials.filter(cc => Object.prototype.hasOwnProperty.call(cc, 'googlecredentialConfig'));
    }),

    doneSaving() {
      const {
        isPostSave,
        model: {
          cluster: {
            gkeConfig = {},
            gkeStatus = {}
          }
        }
      } = this;
      const privateEndpoint = Ember.get(gkeConfig, 'privateClusterConfig.enablePrivateEndpoint') || Ember.get(gkeStatus, 'upstreamSpec.privateClusterConfig.enablePrivateEndpoint') || false;

      if (isPostSave && privateEndpoint) {
        Ember.set(this, 'step', 4);
        return;
      }

      if (this.close) {
        this.close();
      }
    },

    bootstrapGkeV2Cluster() {
      const gkeConfig = this.globalStore.createRecord({
        clusterName: '',
        imported: true,
        zone: 'us-central1-c',
        type: 'gkeclusterconfigspec'
      });
      Ember.setProperties(this, {
        'model.cluster.gkeConfig': gkeConfig,
        'config': gkeConfig
      });
    }

  });

  _exports.default = _default;
});