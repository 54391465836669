define("global-admin/global-registry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4QfgSvS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"app\",\"cluster\",\"project\",\"nsExists\",\"storageClasses\",\"persistentVolumeClaims\",\"showForm\",\"globalRegistryEnabled\",\"cancel\"],[[35,1,[\"app\"]],[35,1,[\"cluster\"]],[35,1,[\"project\"]],[35,1,[\"nsExists\"]],[35,1,[\"storageClasses\"]],[35,1,[\"persistentVolumeClaims\"]],[35,1,[\"showForm\"]],[35,1,[\"globalRegistryEnabled\"]],[30,[36,0],[[32,0],\"cancel\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"registry-catalog\"]}",
    "moduleName": "global-admin/global-registry/template.hbs"
  });

  _exports.default = _default;
});