define("shared/settings/service", ["exports", "shared/utils/constants", "shared/utils/parse-version", "async", "semver"], function (_exports, _constants, _parseVersion, _async, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeName = normalizeName;
  _exports.denormalizeName = denormalizeName;
  _exports.default = void 0;

  function normalizeName(str) {
    return str; // .replace(/\./g, C.SETTING.DOT_CHAR).toLowerCase();
  }

  function denormalizeName(str) {
    return str; // .replace(new RegExp('['+C.SETTING.DOT_CHAR+']','g'),'.').toLowerCase();
  }

  var _default = Ember.Service.extend(Ember.Evented, {
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),
    all: null,
    promiseCount: 0,
    showHeaderBanner: false,
    showFooterBanner: false,

    init() {
      this._super();

      Ember.set(this, 'all', Ember.get(this, 'globalStore').all('setting'));
    },

    unknownProperty(key) {
      var obj = this.findByName(key);

      if (obj) {
        var val = obj.get('value');

        if (val === 'false') {
          return false;
        } else if (val === 'true') {
          return true;
        } else {
          return val;
        }
      }

      return null;
    },

    setUnknownProperty(key, value) {
      if (key !== 'app') {
        var obj = this.findByName(key);

        if (value === undefined) {
          // Delete by set to undefined is not needed for settings
          throw new Error('Deleting settings is not supported');
        }

        if (obj) {
          obj = obj.clone();
        } else {
          obj = Ember.get(this, 'globalStore').createRecord({
            type: 'setting',
            name: denormalizeName(key)
          });
        }

        this.incrementProperty('promiseCount');
        obj.set('value', `${value}`); // Values are all strings in settings.

        obj.save().then(() => {
          this.notifyPropertyChange(normalizeName(key));
        }).catch(err => {
          Ember.get(this, 'growl').fromError(err);
        }).finally(() => {
          this.decrementProperty('promiseCount');
        });
      }

      return value;
    },

    promiseCountObserver: Ember.observer('promiseCount', function () {
      if (Ember.get(this, 'promiseCount') <= 0) {
        this.trigger('settingsPromisesResolved');
      }
    }),

    findByName(name) {
      return Ember.get(this, 'asMap')[normalizeName(name)];
    },

    loadAll() {
      return Ember.get(this, 'globalStore').findAll('setting');
    },

    load(names) {
      if (!Ember.isArray(names)) {
        names = [names];
      }

      var globalStore = Ember.get(this, 'globalStore');
      var promise = new Ember.RSVP.Promise((resolve, reject) => {
        (0, _async.eachLimit)(names, 3, (name, cb) => {
          globalStore.find('setting', denormalizeName(name)).then(() => {
            cb();
          }).catch(err => {
            cb(err);
          });
        }, err => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });
      return promise;
    },

    cliVersion: Ember.computed.alias(`asMap.${_constants.default.SETTING.VERSION_CLI}.value`),
    dockerMachineVersion: Ember.computed.alias(`asMap.${_constants.default.SETTING.VERSION_MACHINE}.value`),
    helmVersion: Ember.computed.alias(`asMap.${_constants.default.SETTING.VERSION_HELM}.value`),
    minDockerVersion: Ember.computed.alias(`asMap.${_constants.default.SETTING.MIN_DOCKER}.value`),
    rancherImage: Ember.computed.alias(`asMap.${_constants.default.SETTING.IMAGE_RANCHER}.value`),
    rancherVersion: Ember.computed.alias(`asMap.${_constants.default.SETTING.VERSION_RANCHER}.value`),
    serverUrl: Ember.computed.alias(`asMap.${_constants.default.SETTING.SERVER_URL}.value`),
    clusterTemplateEnforcement: Ember.computed.alias(`asMap.${_constants.default.SETTING.CLUSTER_TEMPLATE_ENFORCEMENT}.value`),
    uiBanners: Ember.computed.alias(`asMap.${_constants.default.SETTING.UI_BANNERS}.value`),
    uiIssues: Ember.computed.alias(`asMap.${_constants.default.SETTING.UI_ISSUES}.value`),
    hideLocalCluster: Ember.computed.alias(`asMap.${_constants.default.SETTING.HIDE_LOCAL_CLUSTER}.value`),
    shouldHideLocalCluster: Ember.computed('hideLocalCluster', function () {
      if (this.hideLocalCluster === 'true') {
        return true;
      }

      return false;
    }),
    asMap: Ember.computed('all.@each.{name,value,customized}', function () {
      var out = {};
      (Ember.get(this, 'all') || []).forEach(setting => {
        var name = normalizeName(setting.get('name'));
        out[name] = setting;
      });
      return out;
    }),
    uiVersion: Ember.computed('app.version', function () {
      return `v${Ember.get(this, 'app.version')}`;
    }),
    githubIssueUrl: Ember.computed('app.currentRouteName', 'access.{provider,admin}', 'rancherVersion', 'uiVersion', function () {
      var str = '*Describe your issue here*\n\n\n---\n| Useful | Info |\n| :-- | :-- |\n' + `|Versions|Rancher \`${Ember.get(this, 'rancherVersion') || '-'}\` ` + `UI: \`${Ember.get(this, 'uiVersion') || '--'}\` |\n`;
      str += `|Route|\`${Ember.get(this, 'app.currentRouteName')}\`|\n`;
      var url = `${_constants.default.EXT_REFERENCES.GITHUB}/issues/new?body=${encodeURIComponent(str)}`;
      return url;
    }),
    issueUrl: Ember.computed('githubIssueUrl', 'uiIssues', function () {
      return Ember.get(this, 'uiIssues') || Ember.get(this, 'githubIssueUrl');
    }),
    serverUrlIsEmpty: Ember.computed('serverUrl', function () {
      return Ember.isEmpty(Ember.get(this, 'serverUrl'));
    }),
    isRancher: Ember.computed(_constants.default.SETTING.PL, function () {
      const out = (Ember.get(this, _constants.default.SETTING.PL) || '').toUpperCase() === _constants.default.SETTING.PL_RANCHER_VALUE.toUpperCase();

      return out;
    }),
    isEnterprise: Ember.computed('rancherImage', function () {
      return Ember.get(this, 'rancherImage') === 'rancher/enterprise';
    }),
    appName: Ember.computed(_constants.default.SETTING.PL, 'app.appName', 'isRancher', function () {
      if (Ember.get(this, 'isRancher')) {
        return Ember.get(this, 'app.appName');
      }

      return Ember.get(this, _constants.default.SETTING.PL) || '';
    }),
    minorVersion: Ember.computed('rancherVersion', function () {
      let version = Ember.get(this, 'rancherVersion');

      if (!version) {
        return null;
      }

      return (0, _parseVersion.minorVersion)(version);
    }),
    docsBase: Ember.computed('intl.locale', 'minorVersion', 'rancherVersion', 'uiVersion', function () {
      let full = Ember.get(this, 'rancherVersion');
      let version;

      if (!full || (0, _parseVersion.isDevBuild)(full)) {
        if (Ember.get(this, 'uiVersion').includes('master-dev')) {
          version = 'v2.5';
        } else {
          version = (0, _parseVersion.minorVersion)(Ember.get(this, 'uiVersion'));

          if (_semver.default.satisfies(_semver.default.coerce(version), '<2.5.x')) {
            version = `v2.0-v2.4`;
          }
        }
      } else {
        version = (0, _parseVersion.minorVersion)(full);

        if (_semver.default.satisfies(_semver.default.coerce(version), '<2.5.x')) {
          version = `v2.0-v2.4`;
        }
      }

      let lang = ((Ember.get(this, 'intl.locale') || [])[0] || '').replace(/-.*$/, '');

      if (!lang || lang === 'none' || _constants.default.LANGUAGE.DOCS.indexOf(lang) === -1) {
        lang = 'en';
      }

      return `${_constants.default.EXT_REFERENCES.DOCS}/${version}/${lang}`;
    }),
    showBanners: Ember.observer('uiBanners.@each.{showHeader,showFooter}', function () {
      const uiBanners = Ember.get(this, 'uiBanners');

      if (Ember.isEmpty(uiBanners)) {
        Ember.setProperties(this, {
          showHeaderBanner: false,
          showFooterBanner: false
        });
      } else {
        let parsedBanners = {};

        try {
          parsedBanners = JSON.parse(uiBanners);
        } catch {
          // catch SyntaxError
          Ember.setProperties(this, {
            showHeaderBanner: false,
            showFooterBanner: false
          });
          return;
        }

        if (Ember.isEmpty(parsedBanners.showHeader) || typeof parsedBanners.showHeader !== 'string' || parsedBanners.showHeader.toLowerCase() !== 'true') {
          Ember.set(this, 'showHeaderBanner', false);
        } else {
          Ember.set(this, 'showHeaderBanner', true);
        }

        if (Ember.isEmpty(parsedBanners.showFooter) || typeof parsedBanners.showFooter !== 'string' || parsedBanners.showFooter.toLowerCase() !== 'true') {
          Ember.set(this, 'showFooterBanner', false);
        } else {
          Ember.set(this, 'showFooterBanner', true);
        }
      }
    }),
    bannerContent: Ember.computed('uiBanners.@each.banner', 'showHeaderBanner', 'showFooterBanner', function () {
      const uiBanners = Ember.get(this, 'uiBanners');
      let parsedBanners = {};

      try {
        parsedBanners = JSON.parse(uiBanners);
      } catch {// catch SyntaxError
      }

      const banner = Ember.get(parsedBanners, 'banner');

      if (!Ember.isEmpty(banner)) {
        return banner;
      }

      return {};
    }),
    eulaLink: _constants.default.EXT_REFERENCES.EULA
  });

  _exports.default = _default;
});