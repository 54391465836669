define("shared/components/input-cidr/component", ["exports", "ipaddr.js", "shared/components/input-cidr/template"], function (_exports, _ipaddr, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tag: 'span',
    classNames: 'input-cidr-container',
    inputStyles: '',
    value: null,
    invalid: false,
    errorMessage: '',
    disabled: null,
    placeholder: '10.0.0.0/14',
    inputId: null,
    actions: {
      focusInHandler() {
        if (this.invalid) {
          Ember.setProperties(this, {
            invalid: false,
            errorMessage: ''
          });
        }
      },

      focusOutHandler() {
        this.isInvalid();
      }

    },

    isInvalid() {
      const {
        value
      } = this;

      if (Ember.isEmpty(value)) {
        return true;
      }

      try {
        _ipaddr.default.parseCIDR(value);
      } catch (error) {
        Ember.setProperties(this, {
          invalid: true,
          errorMessage: error.message.replace('ipaddr: ', '')
        });
      }
    }

  });

  _exports.default = _default;
});