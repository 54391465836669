define("shared/components/node-ip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "voXNuCX+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,4],[[35,3],\"text-muted\"],null],\" clip\"]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,6],[[35,0,[\"externalIpAddress\"]],[30,[36,5],[[35,0,[\"externalIpAddress\"]],[35,0,[\"ipAddress\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"clipboardText\",\"size\"],[[35,0,[\"externalIpAddress\"]],\"small\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[35,0,[\"externalIpAddress\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"     / \\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,0,[\"ipAddress\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"clipboardText\",\"size\"],[[35,0,[\"ipAddress\"]],\"small\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[35,0,[\"ipAddress\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"format-ip\",\"copy-to-clipboard\",\"textMuted\",\"if\",\"not-eq\",\"and\"]}",
    "moduleName": "shared/components/node-ip/template.hbs"
  });

  _exports.default = _default;
});