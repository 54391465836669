define("ember-template-compiler/index", ["exports", "@ember/-internals/environment", "@ember/canary-features", "@glimmer/syntax", "ember/version", "ember-template-compiler/lib/compat", "ember-template-compiler/lib/system/precompile", "ember-template-compiler/lib/system/compile", "ember-template-compiler/lib/system/compile-options", "ember-template-compiler/lib/plugins/index", "@glimmer/compiler", "ember-template-compiler/lib/system/bootstrap", "ember-template-compiler/lib/system/initializer"], function (_exports, _environment, _canaryFeatures, _GlimmerSyntax, _version, _compat, _precompile, _compile, _compileOptions, _index, _compiler, _bootstrap, _initializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "_preprocess", {
    enumerable: true,
    get: function get() {
      return _GlimmerSyntax.preprocess;
    }
  });
  Object.defineProperty(_exports, "_print", {
    enumerable: true,
    get: function get() {
      return _GlimmerSyntax.print;
    }
  });
  Object.defineProperty(_exports, "VERSION", {
    enumerable: true,
    get: function get() {
      return _version.default;
    }
  });
  Object.defineProperty(_exports, "precompile", {
    enumerable: true,
    get: function get() {
      return _precompile.default;
    }
  });
  Object.defineProperty(_exports, "compile", {
    enumerable: true,
    get: function get() {
      return _compile.default;
    }
  });
  Object.defineProperty(_exports, "compileOptions", {
    enumerable: true,
    get: function get() {
      return _compileOptions.default;
    }
  });
  Object.defineProperty(_exports, "_buildCompileOptions", {
    enumerable: true,
    get: function get() {
      return _compileOptions.buildCompileOptions;
    }
  });
  Object.defineProperty(_exports, "_transformsFor", {
    enumerable: true,
    get: function get() {
      return _compileOptions.transformsFor;
    }
  });
  Object.defineProperty(_exports, "registerPlugin", {
    enumerable: true,
    get: function get() {
      return _compileOptions.registerPlugin;
    }
  });
  Object.defineProperty(_exports, "unregisterPlugin", {
    enumerable: true,
    get: function get() {
      return _compileOptions.unregisterPlugin;
    }
  });
  Object.defineProperty(_exports, "defaultPlugins", {
    enumerable: true,
    get: function get() {
      return _index.default;
    }
  });
  Object.defineProperty(_exports, "_precompile", {
    enumerable: true,
    get: function get() {
      return _compiler.precompile;
    }
  });
  _exports._GlimmerSyntax = _exports._Ember = void 0;
  _exports._GlimmerSyntax = _GlimmerSyntax;

  var _Ember = typeof _environment.context.imports.Ember === 'object' && _environment.context.imports.Ember || {}; // private API used by ember-cli-htmlbars to setup ENV and FEATURES


  _exports._Ember = _Ember;

  if (!_Ember.ENV) {
    _Ember.ENV = _environment.ENV;
  }

  if (!_Ember.FEATURES) {
    _Ember.FEATURES = _canaryFeatures.FEATURES;
  }

  if (!_Ember.VERSION) {
    _Ember.VERSION = _version.default;
  } // used for adding Ember.Handlebars.compile for backwards compat


  (0, _compat.default)(_Ember);
});