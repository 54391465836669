define("shared/components/modal-root/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k+ADCZWa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modalType\",\"component\",\"modalVisible\",\"if\"]}",
    "moduleName": "shared/components/modal-root/template.hbs"
  });

  _exports.default = _default;
});