define("shared/components/table-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTwMwP+B",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"label\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[34,6]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,12],null,[[\"classNames\",\"bulkActions\",\"rowActions\",\"paging\",\"search\",\"sortBy\",\"stickyHeader\",\"descending\",\"headers\",\"body\"],[\"grid fixed mb-0\",false,false,false,[35,11],[35,10],false,[35,9],[35,8],[35,7]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,2],\"row\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,2],[[30,[36,1],[[35,0],\".key\"],null]],null],\":\"]]],[14,0,\"force-wrap\"],[12],[1,[32,3,[\"key\"]]],[13],[2,\"\\n      \"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,2],[[30,[36,1],[[35,0],\".value\"],null]],null],\":\"]]],[14,0,\"force-wrap\"],[12],[1,[30,[36,5],null,[[\"value\"],[[32,3,[\"value\"]]]]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[32,2],\"norows\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[35,0],\".noData\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"labelIntlSec\",\"concat\",\"t\",\"eq\",\"if\",\"pretty-json\",\"header\",\"labelArray\",\"headers\",\"descending\",\"sortBy\",\"search\",\"sortable-table\"]}",
    "moduleName": "shared/components/table-labels/template.hbs"
  });

  _exports.default = _default;
});