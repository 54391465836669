define("global-admin/components/cloud-credential-azure/component", ["exports", "global-admin/components/cloud-credential-azure/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _template.default,
    tag: null,
    config: null
  });

  _exports.default = _default;
});