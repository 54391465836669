define("global-admin/catalog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SiPt/Hlg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"catalogSettings.header\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,1],null,[[\"resource\",\"scope\",\"permission\"],[\"catalog\",\"global\",\"create\"]]]],[24,0,\"btn bg-primary btn-sm\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"add\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"catalogSettings.more.addActionLabel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"catalogs\",\"mode\"],[[35,3],\"global\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"rbac-prevents\",\"action\",\"model\",\"custom-catalog\"]}",
    "moduleName": "global-admin/catalog/template.hbs"
  });

  _exports.default = _default;
});