define("shared/components/settings/settings-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jSIZBVA4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[14,0,\"header has-tabs clearfix\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"nav.admin.settings.tab\"],null]],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"tab-nav\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[6,[37,1],null,[[\"route\"],[\"settings.advanced\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"nav.admin.settings.advanced\"],null]]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "moduleName": "shared/components/settings/settings-header/template.hbs"
  });

  _exports.default = _default;
});