define("global-admin/multi-cluster-apps/catalog/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oqvbDbv9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"cancel\",\"clusters\",\"isClone\",\"multiClusterApp\",\"parentRoute\",\"projects\",\"selectedTemplateUrl\",\"templateKind\",\"templateResource\",\"upgrade\",\"versionLinks\",\"versionsArray\"],[[30,[36,2],[[32,0],\"cancel\"],null],[35,0,[\"clusters\"]],[35,0,[\"isClone\"]],[35,0,[\"multiClusterApp\"]],[35,1],[35,0,[\"projects\"]],[35,0,[\"catalogTemplateUrl\"]],[35,0,[\"tplKind\"]],[35,0,[\"tpl\"]],[35,0,[\"upgradeTemplate\"]],[35,0,[\"versionLinks\"]],[35,0,[\"versionsArray\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"parentRoute\",\"action\",\"new-multi-cluster-app\"]}",
    "moduleName": "global-admin/multi-cluster-apps/catalog/launch/template.hbs"
  });

  _exports.default = _default;
});