define("shared/components/link-to-cluster-driver/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5ePGNNW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"btn btn-sm bg-transparent pull-right\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"icon icon-minus-circle icon-lg\"],[14,\"aria-label\",\"Remove Recent\"],[15,\"onclick\",[30,[36,0],[[32,0],\"removeRecent\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[\"machine-driver \",[30,[36,2],[[35,4],\"generic\"],null],\" \",[34,3]]]],[12],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"driver-name\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[[35,3]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"linkHovered\",\"if\",\"clusterName\",\"genericIcon\",\"driver-name\"]}",
    "moduleName": "shared/components/link-to-cluster-driver/template.hbs"
  });

  _exports.default = _default;
});