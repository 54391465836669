define("shared/components/search-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6tlXuBPH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pull-right search-group input-group\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"value\",\"aria-title\",\"type\",\"class\",\"placeholder\"],[[35,2],[30,[36,1],[\"generic.search\"],null],\"search\",\"input-sm pull-right\",[30,[36,1],[\"generic.search\"],null]]]]],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn bg-transparent text-info pl-10 pr-10\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"clearSearch\"],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"icon icon-close\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"searchText\",\"input\",\"if\"]}",
    "moduleName": "shared/components/search-text/template.hbs"
  });

  _exports.default = _default;
});