define("shared/components/modal-move-namespace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7TWciFga",
    "block": "{\"symbols\":[\"project\",\"item\"],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[\"moveNamespace.title\"],[[\"count\",\"name\"],[[35,2,[\"length\"]],[35,2,[\"firstObject\",\"displayName\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[35,2,[\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,2,[\"displayName\"]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"h2\"],[14,0,\"pt-20\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[\"moveNamespace.to\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-unstyled pl-30\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"selection\",\"value\"],[[35,0],null]]]],[2,\"\\n    \"],[10,\"b\"],[12],[1,[30,[36,5],[\"generic.none\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"selection\",\"value\"],[[35,0],[32,1,[\"id\"]]]]]],[2,\"\\n        \"],[1,[32,1,[\"displayName\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[1,[30,[36,10],null,[[\"errors\"],[[35,9]]]]],[2,\"\\n\"],[1,[30,[36,13],null,[[\"editing\",\"saving\",\"editLabel\",\"save\",\"cancel\"],[true,[35,12],\"moveNamespace.actionLabel\",[30,[36,11],[[32,0],\"save\"],null],[30,[36,11],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"projectId\",\"radio-button\",\"model\",\"-track-array\",\"each\",\"t\",\"gt\",\"if\",\"allProjects\",\"errors\",\"top-errors\",\"action\",\"saving\",\"save-cancel\"]}",
    "moduleName": "shared/components/modal-move-namespace/template.hbs"
  });

  _exports.default = _default;
});