define("shared/components/modal-shell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v1gR/Kfi",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"instance\",\"containerName\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[35,3],[35,2],80,24,[30,[36,0],[[32,0],\"cancel\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[1,[30,[36,1],[\"generic.closeModal\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"containerName\",\"originalModel\",\"container-shell\"]}",
    "moduleName": "shared/components/modal-shell/template.hbs"
  });

  _exports.default = _default;
});