define("shared/components/form-node-taints/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m2kvFuzJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],null,[[\"title\",\"detail\",\"expandAll\",\"expand\",\"expandOnInit\"],[[30,[36,8],[\"formNodeTaints.title\"],null],[30,[36,8],[\"formNodeTaints.detail\"],null],[35,7],[30,[36,6],[[32,0],[35,5]],null],[35,4]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"isNode\",\"nodeTemplateTaints\",\"model\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"nodeTemplateTaints\",\"isNode\",\"node-taints\",\"expandOnInit\",\"expandFn\",\"action\",\"expandAll\",\"t\",\"accordion-list-item\"]}",
    "moduleName": "shared/components/form-node-taints/template.hbs"
  });

  _exports.default = _default;
});