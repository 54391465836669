define("shared/components/schema/input-istiohost/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TvDjao7U",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"value\",\"content\",\"prompt\"],[[35,2],[35,1],[30,[36,0],[\"formIstioHost.prompt\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hostChoices\",\"value\",\"new-select\"]}",
    "moduleName": "shared/components/schema/input-istiohost/template.hbs"
  });

  _exports.default = _default;
});