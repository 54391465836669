define("nodes/components/node-template-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uob22NQF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"row-check\"],[14,\"valign\",\"middle\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"nodeId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"state\"]]]]],[14,0,\"state\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"name\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[35,0,[\"displayName\"]]],[2,\"\\n\"],[6,[37,5],[[35,0,[\"showTransitioningMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"clip text-small \",[34,0,[\"stateColor\"]]]]],[12],[1,[30,[36,1],[[35,0,[\"transitioningMessage\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"owner\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[35,0,[\"creator\",\"displayName\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"provider\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[35,0,[\"displayProvider\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"location\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[35,0,[\"displayLocation\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"size\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[35,0,[\"displaySize\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"actions\"]]]]],[14,0,\"actions\"],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"uc-first\",\"check-box\",\"dt\",\"badge-state\",\"if\",\"action-menu\"]}",
    "moduleName": "nodes/components/node-template-row/template.hbs"
  });

  _exports.default = _default;
});