define("shared/components/schema/input-relative-service/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KLLUStwS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[14,5,\"width: 100%\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"value\",\"classNames\",\"placeholder\"],[[35,1],[35,2],\"[stack-name/]service-name\"]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"input-group-addon bg-default p-0\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"input-group-btn p-0\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn bg-transparent text-info pl-10 pr-10\"],[24,4,\"button\"],[4,[38,5],[[32,0],\"standard\"],null],[12],[10,\"i\"],[14,0,\"icon icon-close\"],[12],[13],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"classNames\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"optionGroupPath\",\"prompt\",\"localizedPrompt\"],[[35,2],[35,1],[35,0],\"value\",\"name\",\"group\",\"schema.inputService.prompt\",true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"list\",\"value\",\"inputClass\",\"new-select\",\"input\",\"action\",\"custom\",\"if\"]}",
    "moduleName": "shared/components/schema/input-relative-service/template.hbs"
  });

  _exports.default = _default;
});