define("global-admin/security/authentication/shibboleth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "km7h/GDu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"saml-config\",[],[[\"@createLabel\",\"@editLabel\",\"@editing\",\"@model\",\"@providerName\",\"@providerNamePath\",\"@saveLabel\"],[\"authPage.shibboleth.buttonText.pre\",\"authPage.shibboleth.buttonText.edit\",[34,0],[34,1],\"shibboleth\",\"authPage.saml.providerName.shibboleth\",\"authPage.shibboleth.buttonText.post\"]],null]],\"hasEval\":false,\"upvars\":[\"editing\",\"model\"]}",
    "moduleName": "global-admin/security/authentication/shibboleth/template.hbs"
  });

  _exports.default = _default;
});