define("shared/components/form-global-resource-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sT1hjNBY",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"newMultiClusterApp.roles.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,1,\"mc-app-role-project-member\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"selection\",\"value\",\"id\"],[[35,4],\"project-member\",\"mc-app-role-project-member\"]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"newMultiClusterApp.roles.radios.project\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,1,\"mc-app-role-cluster-owner\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"selection\",\"value\",\"id\"],[[35,4],\"cluster-owner\",\"mc-app-role-cluster-owner\"]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"newMultiClusterApp.roles.radios.cluster\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,6],[[35,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"newMultiClusterApp.roles.additionalRoles\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"table\"],[14,0,\"table fixed no-lines mt-5 mb-10\"],[12],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"otherRoles\",\"-track-array\",\"each\",\"role\",\"radio-button\",\"if\"]}",
    "moduleName": "shared/components/form-global-resource-roles/template.hbs"
  });

  _exports.default = _default;
});