define("global-admin/multi-cluster-apps/catalog/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TlcYUNyB",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"application\",\"catalogId\",\"category\",\"categoryAction\",\"launch\",\"launchRoute\",\"model\",\"parentRoute\",\"refresh\",\"showHeader\"],[[35,6],[35,5],[35,4],[30,[36,0],[[32,0],\"categoryAction\"],null],[30,[36,0],[[32,0],\"launch\"],null],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"refresh\"],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"parentRoute\",\"model\",\"launchRoute\",\"category\",\"catalogId\",\"application\",\"catalog-index\"]}",
    "moduleName": "global-admin/multi-cluster-apps/catalog/index/template.hbs"
  });

  _exports.default = _default;
});