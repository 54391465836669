define("shared/components/input-answer-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XXeKnJo0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n  \"],[1,[30,[36,8],[[35,0,[\"label\"]],[35,0,[\"variable\"]]],null]],[2,\"\\n\"],[6,[37,9],[[35,0,[\"required\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,7]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,0,[\"supported\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[35,0,[\"inputComponent\"]]],[[\"field\",\"value\",\"namespaceId\",\"namespace\"],[[35,0],[35,5],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"type\",\"value\",\"class\"],[\"text\",[35,0,[\"answer\"]],\"form-control\"]]]],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"inputAnswers.unknownType\"],null]],[2,\" \"],[1,[35,0,[\"type\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n  \"],[1,[35,0,[\"description\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"question\",\"input\",\"t\",\"namespace\",\"namespaceId\",\"answer\",\"component\",\"field-required\",\"or\",\"if\"]}",
    "moduleName": "shared/components/input-answer-row/template.hbs"
  });

  _exports.default = _default;
});