define("nodes/components/driver-other/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eq4HxZn+",
    "block": "{\"symbols\":[\"al\",\"expandFn\",\"value\",\"key\"],\"statements\":[[6,[37,22],null,[[\"showExpandAll\"],[false]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"over-hr\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,6]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[35,1,[\"resourceFields\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"resource\",\"resourceType\",\"field\",\"value\",\"schema\",\"typeDocs\"],[[35,2],[35,4],[32,4],[30,[36,3],[[35,2],[32,4]],null],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"over-hr\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,9]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,11],null,[[\"model\",\"nameRequired\",\"rowClass\"],[[35,10],true,\"row mb-10\"]]]],[2,\"\\n\\n  \"],[1,[30,[36,14],null,[[\"initialLabels\",\"setLabels\"],[[35,13,[\"labels\"]],[30,[36,12],[[32,0],\"setLabels\"],null]]]]],[2,\"\\n\\n  \"],[1,[30,[36,15],null,[[\"model\",\"expand\"],[[35,10],[30,[36,12],[[32,0],[32,2]],null]]]]],[2,\"\\n\\n  \"],[1,[30,[36,17],null,[[\"machine\",\"showEngineUrl\"],[[35,10],[35,16]]]]],[2,\"\\n\\n  \"],[1,[30,[36,19],null,[[\"errors\"],[[35,18]]]]],[2,\"\\n  \"],[1,[30,[36,21],null,[[\"save\",\"cancel\",\"editing\"],[[30,[36,12],[[32,0],\"save\"],null],[30,[36,12],[[32,0],\"cancel\"],null],[35,20]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"typeDocumentations\",\"schema\",\"config\",\"get\",\"driverName\",\"api-field\",\"driverOptionsTitle\",\"-each-in\",\"each\",\"templateOptionsTitle\",\"model\",\"form-name-description\",\"action\",\"labelResource\",\"form-user-labels\",\"form-node-taints\",\"showEngineUrl\",\"form-engine-opts\",\"errors\",\"top-errors\",\"editing\",\"save-cancel\",\"accordion-list\"]}",
    "moduleName": "nodes/components/driver-other/template.hbs"
  });

  _exports.default = _default;
});