define("shared/components/action-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ZvJ74ls",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"pull-left\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n\"],[10,\"i\"],[15,0,[31,[[34,3],\" \",[34,2],\" pull-right ml-10\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"t\",\"icon\",\"prefix\"]}",
    "moduleName": "shared/components/action-menu-item/template.hbs"
  });

  _exports.default = _default;
});