define("shared/components/form-versions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wGH/vRbp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],\"view\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"classNames\",\"content\",\"value\",\"disabled\"],[\"form-control\",[35,3],[35,2],[35,1]]]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],\"edit\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"formVersions.helpBlock.label\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"disabled\",\"value\",\"versionChoices\",\"new-select\",\"mode\",\"eq\",\"if\"]}",
    "moduleName": "shared/components/form-versions/template.hbs"
  });

  _exports.default = _default;
});