define("global-admin/components/global-dns-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPi+QoT/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[14,0,\"main-row\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-check\"],[14,\"valign\",\"middle\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"canBulkRemove\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"nodeId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"state\"]]]]],[14,0,\"state\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"model\"],[[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"fqdn\"]]]]],[12],[2,\"\\n    \"],[1,[35,0,[\"fqdn\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"projectIds\"]]]]],[12],[2,\"\\n    \"],[1,[35,0,[\"projectIds\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"providerName\"]]]]],[12],[2,\"\\n    \"],[1,[35,0,[\"providerName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,3,[\"actions\"]],\" \"]]],[14,0,\"actions\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"model\"],[[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"check-box\",\"if\",\"dt\",\"badge-state\",\"action-menu\"]}",
    "moduleName": "global-admin/components/global-dns-row/template.hbs"
  });

  _exports.default = _default;
});