define("global-admin/components/modal-add-cloud-credential/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YNKl5CSl",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"cancelAdd\",\"doneSavingCloudCredential\",\"mode\",\"originalModel\",\"parseAndCollectErrors\"],[[30,[36,0],[[32,0],\"cancel\"],null],[30,[36,0],[[32,0],\"close\"],null],[35,2],[35,1,[\"cloudCredential\"]],[30,[36,0],[[32,0],\"errorHandler\"],null]]]]],[2,\"\\n\"],[1,[30,[36,5],null,[[\"errors\"],[[35,4]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"modalOpts\",\"mode\",\"cru-cloud-credential\",\"errors\",\"top-errors\"]}",
    "moduleName": "global-admin/components/modal-add-cloud-credential/template.hbs"
  });

  _exports.default = _default;
});