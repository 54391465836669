define("shared/components/code-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOCrLjXo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"code\"],[15,0,[31,[[34,0]]]],[12],[2,[34,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"languageClass\",\"highlighted\"]}",
    "moduleName": "shared/components/code-block/template.hbs"
  });

  _exports.default = _default;
});