define("shared/components/storage-class/provisioner-longhorn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V3DbKrZm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row mb-20\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[8,\"form-key-value\",[],[[\"@addActionLabel\",\"@changed\",\"@editing\",\"@initialMap\"],[\"clusterNew.googlegke.nodeLabels.addAction\",[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null],[30,[36,5],[[30,[36,4],[[35,3],\"edit\"],null]],null],[34,0]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"mut\",\"action\",\"mode\",\"eq\",\"not\"]}",
    "moduleName": "shared/components/storage-class/provisioner-longhorn/template.hbs"
  });

  _exports.default = _default;
});