define("shared/components/api-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tUXaaKPV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n  \"],[1,[35,0,[\"name\"]]],[1,[30,[36,7],[[35,0,[\"required\"]],\"*\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,8],null,[[\"classNames\",\"value\",\"placeholder\",\"rows\"],[\"form-control\",[35,1],[35,3],1]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],\"boolean\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"type\",\"checked\",\"classNames\"],[\"checkbox\",[35,1],\"m-10\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],\"float\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[\"number\",[35,1],\"form-control\",[35,3],[35,0,[\"min\"]],[35,0,[\"max\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],\"int\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[[35,1],\"form-control\",[35,3],[35,0,[\"min\"]],[35,0,[\"max\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[35,1],\"form-control\",[35,0,[\"placeholder\"]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[14,0,\"text-info\"],[12],[2,\"\\n  \"],[1,[35,0,[\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldDef\",\"value\",\"input\",\"placeholder\",\"input-integer\",\"fieldType\",\"eq\",\"if\",\"schema/input-multiline\",\"specialField\"]}",
    "moduleName": "shared/components/api-field/template.hbs"
  });

  _exports.default = _default;
});