define("shared/components/schema/input-secret/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d5NQJrS8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"value\"],[\"form-control\",[35,3],[30,[36,2],[\"schema.inputSecret.\",[35,1]],null],true,[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"type\",\"concat\",\"filtered\",\"new-select\"]}",
    "moduleName": "shared/components/schema/input-secret/template.hbs"
  });

  _exports.default = _default;
});