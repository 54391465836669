define("global-admin/components/cloud-credential-azure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBRKKxQp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,\"for\",\"azure-client-id\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"modalAddCloudKey.azure.clientId.label\"],null]],[2,\"\\n      \"],[8,\"field-required\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@classNames\",\"@id\",\"@type\",\"@value\"],[\"form-control\",\"azure-client-id\",\"text\",[30,[36,2],[[35,1,[\"clientId\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,\"for\",\"azure-client-secret\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"modalAddCloudKey.azure.clientSecret.label\"],null]],[2,\"\\n      \"],[8,\"field-required\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@classNames\",\"@id\",\"@type\",\"@value\"],[\"form-control\",\"azure-client-secret\",\"password\",[30,[36,2],[[35,1,[\"clientSecret\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,\"for\",\"azure-subscription-id\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"modalAddCloudKey.azure.subscriptionId.label\"],null]],[2,\"\\n      \"],[8,\"field-required\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@classNames\",\"@id\",\"@type\",\"@value\"],[\"form-control\",\"azure-subscription-id\",\"text\",[30,[36,2],[[35,1,[\"subscriptionId\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"config\",\"mut\"]}",
    "moduleName": "global-admin/components/cloud-credential-azure/template.hbs"
  });

  _exports.default = _default;
});