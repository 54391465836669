define("shared/components/cru-cluster-template-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KeaxmBzI",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"question\",\"dt\"],\"statements\":[[8,\"sortable-table\",[[24,\"classNames\",\"grid\"]],[[\"@body\",\"@bulkActions\",\"@descending\",\"@headers\",\"@pagingLabel\",\"@searchText\",\"@sortBy\",\"@stickyHeader\"],[[34,7],[34,8],[34,9],[34,10],\"pagination.member\",[34,11],[34,12],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"row\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cluster-template-question-row\",[],[[\"@questions\",\"@question\",\"@removeQuestion\",\"@applyClusterTemplate\",\"@clusterTemplateCreate\"],[[34,3],[32,3],[34,4],[34,5],[34,6]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"nomatch\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[14,\"colspan\",\"5\"],[14,0,\"no-match\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"clusterTemplateQuestions.table.noMatch\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"norows\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[14,\"colspan\",\"5\"],[14,0,\"no-data\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"clusterTemplateQuestions.table.noData\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3,4]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"schemaQuestions\",\"removeQuestion\",\"applyClusterTemplate\",\"clusterTemplateCreate\",\"rows\",\"bulkActions\",\"descending\",\"questionsHeaders\",\"searchText\",\"sortBy\"]}",
    "moduleName": "shared/components/cru-cluster-template-questions/template.hbs"
  });

  _exports.default = _default;
});