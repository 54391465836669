define("shared/components/advanced-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ptuaZWLt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0],\"hide\"],null]]]],[12],[2,\"\\n  \"],[18,1,[[35,0]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-right text-small\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent p-0\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"toggle\"],null],[12],[1,[30,[36,4],[[30,[36,3],[[35,0],\"advancedSection.hideText\",\"advancedSection.showText\"],null]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"advanced\",\"unless\",\"action\",\"if\",\"t\"]}",
    "moduleName": "shared/components/advanced-section/template.hbs"
  });

  _exports.default = _default;
});