define("shared/components/form-ingress-rule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tSiZTbo9",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[30,[36,1],[\"formIngress.label\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],null,[[\"editing\",\"ingress\",\"namespace\",\"removeRule\",\"rule\",\"rules\"],[[35,5],[35,4],[35,3],[30,[36,0],[[32,0],\"removeRule\"],null],[32,1],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"p-20\"],[12],[1,[30,[36,1],[\"formIngress.noRules\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-link icon-btn pull-right\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"addRule\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-plus text-small\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[\"formIngress.addRuleLabel\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"rules\",\"namespace\",\"ingress\",\"editing\",\"form-ingress-rows\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "shared/components/form-ingress-rule/template.hbs"
  });

  _exports.default = _default;
});