define("shared/components/upgrade-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cb2jhMj7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"tooltipFor\"],[\"tooltip-basic\",[35,3],\"tooltip-static\",\"tooltip-base\",\"upgrade\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[30,[36,1],[\"upgradeBtn.status.\",[35,0]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[30,[36,1],[\"upgradeBtn.status.\",[35,0]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"upgradeStatus\",\"concat\",\"t\",\"currentVersion\",\"tooltip-element\",\"if\"]}",
    "moduleName": "shared/components/upgrade-btn/template.hbs"
  });

  _exports.default = _default;
});