define("shared/components/storage-class/provisioner-local-storage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2NRn6TNN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"generic.na\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "shared/components/storage-class/provisioner-local-storage/template.hbs"
  });

  _exports.default = _default;
});