define("shared/components/input-integer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Kzp758c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"editing\",\"if\"]}",
    "moduleName": "shared/components/input-integer/template.hbs"
  });

  _exports.default = _default;
});