define("global-admin/cluster-templates/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qkl8mQLg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clusterTemplatesPage.index.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"disabled\",\"route\"],[\"btn btn-sm bg-primary\",[30,[36,1],null,[[\"resource\",\"scope\",\"permission\"],[\"cluster\",\"global\",\"create\"]]],\"cluster-templates.new\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"clusterTemplatesPage.index.newTemplate\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"cluster-templates-index-container\"],[12],[2,\"\\n  \"],[8,\"cluster-templates-table\",[],[[\"@clusterTemplates\",\"@clusterTemplateRevisions\"],[[34,3,[\"clusterTemplates\"]],[34,3,[\"clusterTemplateRevisions\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"rbac-prevents\",\"link-to\",\"model\"]}",
    "moduleName": "global-admin/cluster-templates/index/template.hbs"
  });

  _exports.default = _default;
});