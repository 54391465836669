define("monitoring/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('index', {
      path: '/'
    });
    this.route('cluster-setting');
    this.route('project-setting');
    this.route('node-detail', {
      path: '/:node_id'
    }); // Node metrics view for embedding in Cluster Dashboard

    this.route('node-graphs', {
      path: '/:node_id/metrics'
    });
  });

  _exports.default = _default;
});