define("global-admin/security/authentication/localauth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kdN8Jjjl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"authPage.localAuth.subtext.enabled.text\"],[[\"appName\"],[[35,2,[\"appName\"]]]]]],[2,\"\\n\"],[6,[37,3],null,[[\"route\"],[\"security.accounts.users\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"authPage.localAuth.subtext.enabled.linkText\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"authPage.localAuth.subtext.enabled.alwaysOn\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"headerText\",\"settings\",\"link-to\"]}",
    "moduleName": "global-admin/security/authentication/localauth/template.hbs"
  });

  _exports.default = _default;
});