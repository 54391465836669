define("shared/components/cluster-template-revision-upgrade-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbpWxZc+",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"isClusterTemplateUpgradeAvailable\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"type\",\"model\",\"tooltipTemplate\",\"inlineBlock\"],[\"tooltip-basic\",[30,[36,1],[\"clusterTemplateRevisionUpgradeNotification.tooltip\"],[[\"revision\"],[[35,0,[\"clusterTemplate\",\"latestRevision\",\"displayName\"]]]]],\"tooltip-static\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-stack rke-template-revision-upgrade-notification\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-circle icon-stack-1x\"],[12],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-circle-o icon-stack-1x\"],[12],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-notification icon-stack-1x\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cluster\",\"t\",\"tooltip-element\",\"if\"]}",
    "moduleName": "shared/components/cluster-template-revision-upgrade-notification/template.hbs"
  });

  _exports.default = _default;
});