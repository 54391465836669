define("shared/components/sortable-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XJRRXW1K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"a\"],[14,0,\"btn bg-transparent\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,3,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[[34,3,[\"icon\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,3,[\"translationKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],[[35,3,[\"translationKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,3,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[35,3,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,1],[[35,3,[\"sort\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-stack\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-sort icon-stack-1x faded\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"icon icon-sort-down icon-stack-1x\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"icon icon-sort-up icon-stack-1x\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"no-sort-spacer\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\" \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"activeDescending\",\"if\",\"activeAscending\",\"header\",\"t\"]}",
    "moduleName": "shared/components/sortable-thead/template.hbs"
  });

  _exports.default = _default;
});