define("shared/components/error-sub-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9knpY2Jr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[14,0,\"sub-row no-top auto-height\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,\"colspan\",[31,[[34,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"td\"],[14,0,\"pb-5\"],[15,\"colspan\",[31,[[34,3]]]],[12],[2,\"\\n    \"],[10,\"small\"],[15,0,[31,[[34,4,[\"stateColor\"]]]]],[12],[1,[30,[36,5],[[35,4,[\"transitioningMessage\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,\"colspan\",[31,[[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"rightColspan\",\"leftColspan\",\"if\",\"mainColspan\",\"model\",\"uc-first\"]}",
    "moduleName": "shared/components/error-sub-row/template.hbs"
  });

  _exports.default = _default;
});